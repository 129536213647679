<template>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
        <DischargeNote>
            <table>
                <tr>
                    <td class="table-column">OT Date & Time</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ formatDateToDdMmYy(note.operation_date) }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Name of Operation</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong> {{ note.product.name }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Indication</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ startCase(note.indication) }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Anesthesia</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ startCase(note.anestesia) }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Surgeon</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ joinArrayOfString(consultants.surgeon) }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Asst. Surgeon</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ joinArrayOfString(consultants.ass_surgeon) }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Anestesiologist</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ joinArrayOfString(consultants.anestesiologist) }}</strong>
                    </td>
                </tr>
                <tr v-if="consultants.nurse.length">
                    <td class="table-column">Nurse</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ joinArrayOfString(consultants.nurse) }}</strong>
                    </td>
                </tr>
            </table>
        </DischargeNote>
    </div>
</template>

<script setup>
import DischargeNote from '@/components/molecule/company/hospital/ipd-station/DischargeNote.vue';
import { joinArrayOfString, formatDateToDdMmYy } from '@/services/utils/global';
import { onMounted, reactive } from 'vue';
import { startCase } from 'lodash';

const props = defineProps({
    note: Object
})

const consultants = reactive({
    surgeon: [],
    anestesiologist: [],
    ass_surgeon: [],
    nurse: [],
})

const groupByConsultantName = () => {
    for(const item of props.note.ot_order_service){
        consultants[item.pivot.type].push(item.name);
    }
}

const convertArraytoString = (notes) => {
    const note = notes.map(obj => obj.note);
    return joinArrayOfString(note);
}

onMounted(() => {
    groupByConsultantName();
})
</script>