<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    						
                    <tr>
                        <th>Photo</th>
                        <th class="patient-details">Baby name</th>
                        <th>Gender</th>
                        <th>Weight</th>
                        <th>APGAR Score</th>
                        <!-- <th>Birth Certificate</th> -->
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            <img :src=item.photo alt="avatar" class="profile-pic">
                        </td>

                        <td> 
                            <div class="col">
                                {{ item.name }}
                            </div>
                        </td>

                        <td>
                            {{ toCapitalizeFirst(item.gender) }}
                        </td>

                        <td>
                            {{ item.weight }}
                        </td>

                        <td>
                            {{ item.apgar_score }}
                        </td>

                        <!-- <td>
                            <div class="action">
                                <button class="btn btn-outline-primary btn-sm">
                                    <i class="fas fa-print"></i>
                                    Birth Certificate
                                </button>
                            </div>
                        </td> -->

                        <td>
                            <div class="action">
                                <button @click="$emit('onEditBabyNote', item)" class="btn btn-primary btn-sm">
                                    <i class="fas fa-pen"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { toCapitalizeFirst } from '@/services/utils/global';

const $route = useRoute();
const $router = useRouter();
const props = defineProps({
    tableItems: {
        type: Array,
        default: []
    },
    onEditBabyNote: Function
})

const emit = defineEmits(['onEditBabyNote']);
    
</script>

<style scoped>

.admission-no{
    width: 15%;
}
.patient-details{
    width: 25%;
}
.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>