<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>				
                    <tr>
                        <th>OT No</th>
                        <th>Date & Time</th>
                        <th>Name of Operation</th>
                        <th>Surgeons</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td> 
                            <div class="col">
                                {{ item.id }}
                            </div>
                        </td>

                        <td>
                            {{ item.operation_date }}
                        </td>

                        <td>
                            {{ item.product ? item.product.name : '' }}
                        </td>

                        <td>
                            {{ getDoctorsName(item.ot_order_service) }}
                        </td>

                        <td>
                            <div class="action">
                                <button @click="editOTOrder(item)" class="btn btn-primary btn-sm">
                                    <i class="fas fa-pen"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";

    const $route = useRoute();
    const $router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        },
        onEditOTOrder: Function,
    })

    const emit = defineEmits(['onEditOTOrder']);

    const editOTOrder = (item) => {
        emit('onEditOTOrder', item)
    }

    const getDoctorsName = (services) => {
        if(!services) return '';

        const doctors = services.filter(item => item.pivot.type === 'surgeon')
                                .map(item => item.name);
                                
        return doctors.join(", ");
    }
</script>

<style scoped>

.admission-no{
    width: 15%;
}
.patient-details{
    width: 25%;
}
.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>