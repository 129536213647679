<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                title="Baby Note"
                btnTitle="Add new"
                @onClickAddNewButton="onClickAddNew"
            />
        </div>

        <div class="col-12 px-2 mt-2 mb-2">
            <ListTable 
                :tableItems="babyNotes"
                @onEditBabyNote="onEditBabyNote"
            />
        </div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <BabyNoteModal
            v-if="store.state.isModalOpen"
            :initialData="initialData"
            :editData="editData"
            :admissionInfo="admissionInfo"
            @onSubmit="onSubmit"
        /> 
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/ipd-station/BabyNoteTable.vue';
import BabyNoteModal from '@/components/molecule/company/hospital/ipd-station/BabyNoteModal.vue';
import handleHospital from '@/services/modules/hospital'
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'

const props = defineProps({
    admissionInfo: Object,
})

const { fetchInitialBabyNoteData, fetchBabyNoteList, fetchSingleBabyNote } = handleHospital();

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const babyNotes = ref([]);
const editData = ref(null);
const initialData = reactive({
    note: {},
    genderList: []
});
const params = reactive({
    searchTxt: '',
    offset: 50
});

const onClickAddNew = () => {
    editData.value = null;
    initialData.note.selectedNotes = [];
    initialData.note.newNotesByDoctor = [];
    store.state.isModalOpen = true;
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const onSubmit = async () => {
   await fetchBabyNotes();
}

const onEditBabyNote = async (item) => {
    const query = getQuery();
    await fetchSingleBabyNote(query, item.id)
        .then((res) => {
            if (!res.status) return showError(res.message)
            editData.value = res.data;
            store.state.isModalOpen = true;
        })
}

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}
async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await fetchBabyNotes();
}

const fetchInitialData = async () => {
    let query = `?company_id=${route.params.companyId}`;
    await fetchInitialBabyNoteData(query)
        .then((res) => {
            if (!res.status) return showError(res.message)
            initialData.note = res.data.baby_notes;
            initialData.genderList = res.data.gender;
        })
}

const fetchBabyNotes = async () => {
    const query = getQuery();
    loading.value = true

    await fetchBabyNoteList(query).then(res => {
        loading.value = false
        if (!res.status){
            store.commit("resetPagination")
            return
        }
        babyNotes.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&searchTxt=${params.searchTxt}`;
    query += '&offset=' + params.offset
    query += '&ipd_register_id=' + route.query.admissionId;
    return query;
}

onMounted( async () => {
    await Promise.all([
        fetchInitialData(),
        fetchBabyNotes()
    ]);
})
</script>